import PropTypes from 'prop-types';

import { RecordCodeShape } from '../../shapes/record-code.shape';
import { StudentShape } from '../../shapes/student.shape';
import { IRegistrationType } from '../../types/registration-type';

export const PreInscriptionShape = PropTypes.shape({
  school: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  schoolYear: PropTypes.string,
  student: StudentShape.isRequired,
  registrationType: PropTypes.oneOf(Object.values(IRegistrationType)).isRequired,
  schoolLevel: PropTypes.string.isRequired,
  reason: RecordCodeShape.isRequired,
  academy: RecordCodeShape.isRequired,
  department: PropTypes.string,
  situation: RecordCodeShape.isRequired,
  meetingDate: PropTypes.string,
  dossierComment: PropTypes.string,
  responseDa: PropTypes.string,
  responseDaComment: PropTypes.string,
  responseDaDate: PropTypes.string,
  inscription: PropTypes.string,
  envoiRapo: PropTypes.string,
  envoiRapoComment: PropTypes.string,
  envoiRapoDate: PropTypes.string,
  responseRapo: PropTypes.string,
  responseRapoComment: PropTypes.string,
  responseRapoDate: PropTypes.string,
  recoursTa: PropTypes.string,
  recoursTaComment: PropTypes.string,
  filingDate: PropTypes.string,
  referredComment: PropTypes.string,
  audienceDate: PropTypes.string,
  responseTa: PropTypes.string,
  responseTaComment: PropTypes.string,
  refundComment: PropTypes.string,
  depasseComment: PropTypes.string,
  paymentDate: PropTypes.string,
});
